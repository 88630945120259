<template>
	<div class="none-view">
		<router-view v-slot="{ Component }">
			<keep-alive :exclude="newExclude" :max="15">
				<component :is="Component" :key="$route.name" />
			</keep-alive>
		</router-view>
	</div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useAppStore } from '@/stores/app'

const exclude = [
	'maintenanceEdit',
	'addCarExamine',
	'investorExamine',
	'contractReview',
	'eleArchive',
	'contractPaperArchive',
	'useExpectedPaper',
	'numberExamine',
	'detRepairsCentre',
	'detRepairsMange',
	'receivableEdit',
	'receivableDetails',
	'inspectionReportDetails',
	'summaryDetails',
	'visitDetail',
	'businessDetail',
	'litigationDetail',
	'statementListDetails',
	'performDetails',
	'collectionDetil',
	'equipment360',
	'flowEdit',
	'codeNumberList',
	'codeNumberAddorEdit',
	'menuPc',
	'menuApp',
	'menuEdit',
	'addRecord',
	'addSendRecord',
	'addRepairsMange',
	'stopReport', // 报停
	'stopReportDetail', // 报停详情
	'stopReportDelayDetail', // 报停延期详情
	'stopReportRecoveryDetail', // 报停恢复详情
	'maintainRules', // 保养规则
	'addRules', // 添加保养规则
	'lockDetail',
	'unLockDetail',
	'litigationBillAdd',
	'modelSetDetails', //设备型号设置详情
	'maintainDetail', // 保养报告详情
	'maintenanceSchedule',
	'scheduleDetails',
	'delayPayDetail', //延期付款详情
	'delayPayApproval', // 延期付款审核
	'urgentRecallDetail', //催收详情
	'logisticsCompanyAddEdit', // 添加物流公司
	'capacityInfo',
	'logisticsDetail', // 需求单详情
	'addLogistics', // 需求单添加编辑
	'insuranceAdd', // 导入保单
	'logisticsSyncEdit', // 需求单同步编辑
	'approachDetails', // 进场详情
	'exitDetails', // 退场详情
	'partsWriteOffRecord', // 配件核销记录
	'logisticsDetalis',
	'changeDerails',
	'contractChangeSub',
	'addTool',
	'toolDetail',
	'equipmentStatisticsDetail', // 设备区域统计详情
	'compensateMangeDetail', // 索赔详情
	'compensateHandleDetail', // 索赔 - 实物归还管理详情
	'disposeAdd', // 新增设备处置
	'disposeDetail', // 处置详情
	'backlogTemplateAdd', //新增模板
	'backlogTemplateDetail', // 模板详情
	// 'inspectionRules', // 巡检规则
	'publicCheckRules', // 添加巡检规则
	'checkRulesDetail', // 巡检详情
	// 'inspectionLog', // 巡检记录
	'inspectionLogDetail', // 巡检记录详情
	'inspectionManage', // 巡检管理
	'lowGoodsDetail', // 低质易耗品详情
	'lowGoodsAdd', // 低质易耗品添加
	'accessoryInventoryDetail', // 配件库存 - 详情
	'detailsProject', // 项目详情
	'claimReductionManageDetail', // 索赔减免详情
	'maintenanceDetails', // 维修减免详情
	'refundDetail', // 客户退款详情
	'litigationExamine', // 诉讼管理审批
	'businessTransfer',
	'initiateTransfer', // 业务划转发起
	'initiateTransferDetail', // 业务划转详情
	'businessHandover',
	'businessHandoverDetail', // 业务交接详情
	'equipmentLock', // 设备锁机
	'caseListDetail', // 案件详情
	'historicalBillAddStepOne', // 历史单据补录第一步
	'historicalBillAddStepTwo', // 历史单据补录第二步
	'historicalBillAddStepThree', // 历史单据补录第三步
	'historicalBillAddStepFour', // 历史单据补录第四步
	'historicalBillAddStepFive', // 历史单据补录第⑤步
	'exigencyPartsDetail', // 紧急配件详情
	'companyLinkmanDetail', // 企业联系人详情
	'authorizationLetterDetail', // 授权书详情
	'authorizationLetterArchiveDetail', // 授权书归档详情
	'authorizationChangeDetail',
	'authorizationChangeArchiveDetail',
	'equipmentAdd', // 设备清单-新增设备
	'equipmentEdit', // 设备清单-编辑设备
	'flowTypeDetail', // 流程引擎-业务类型
	'flowConfig', // 流程引擎-引擎配置
	'selfDefinedFlowEdit', // 自定义流程引擎-引擎配置
	'userCenterSet', // 用户中心-设置
	'refabricationDetail', // 再制造详情
	'allotAdd', // 调拨创建
	'allotDetails', // 调拨详情
	'repairsCentre', // 维修详情
]
const newExclude = computed(() => {
	let list = [...exclude, ...useAppStore().exclude]
	return list
})
</script>

<style>
.none-view {
	width: 100%;
	height: 100%;
}
</style>
