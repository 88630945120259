import RouteView from '@/views/layout/routerView.vue'

export default {
	path: 'statistics',
	name: 'statistics',
	component: RouteView,
	children: [
		// 交接个人工时
		{
			path: 'personalWorkingHours/associatePersonalWorkingHoursList',
			name: 'associatePersonalWorkingHoursList',
			component: () =>
				import(
					'@/views/statistics/personalWorkingHours/associate/AssociatePersonalWorkingHoursList.vue'
				),
		},
		// 维保个人工时
		{
			path: 'personalWorkingHours/maintenancePersonalWorkingHoursList',
			name: 'maintenancePersonalWorkingHoursList',
			component: () =>
				import(
					'@/views/statistics/personalWorkingHours/maintenance/MaintenancePersonalWorkingHoursList.vue'
				),
		},
		// 进退场设备查询
		{
			path: 'equipmentLease',
			name: 'equipmentLease',
			component: () => import('@/views/statistics/equipmentLease/EquipmentLeaseList.vue'),
		},
		// 再制造工时统计
		{
			path: 'refabricationWorkingHours',
			name: 'refabricationWorkingHours',
			component: () => import('@/views/statistics/refabricationWorkingHours/index.vue'),
		},
		// 出租率统计
		{
			path: 'lettingRate',
			name: 'lettingRate',
			component: () => import('@/views/statistics/lettingRate/lettingRate/index.vue'),
		},
	],
}
